import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Button from "@elements/Button"
import MovingElement from "@elements/MovingElement"
import { motion } from "framer-motion"
import { useStateContext } from "@context/stateContext"

const Section = styled.section`
  ${tw`relative z-10 flex grid flex-col h-auto grid-cols-12 2xl:min-h-screen`}
`
const HeadlineWrap = styled.div`
  ${tw`relative z-10 flex flex-col items-center col-span-12 font-black text-left text-black md:col-span-6 font-ubuntu md:text-right md:mt-c200 md:mb-auto px-offset md:px-offsetMd lg:px-16 xl:pl-24 xl:pr-10`}
`
const DescriptionGrid = styled.div`
  ${tw`flex flex-col col-span-12 md:relative md:col-span-6 mt-c20 md:mt-0`}
`
const Description = styled.div`
  ${tw`relative z-10 flex flex-col items-center px-8 pt-6 pb-10 font-normal text-center text-blue md:col-start-2 xl:w-3/4 sm:px-12`}
  a {
    ${tw`font-bold underline`}
  }
`

const Bracket = styled.span`
  ${tw`absolute right-0 z-0 hidden font-bold md:block font-firacode md:-top-6 xl:-top-10 md:text-400/400 xl:text-500/500 text-yellow`}
  writing-mode: vertical-lr;
`
const ArrowIcon = ({ className }) => (
  <div className={`${className} max-w-[50px]`}>
    <svg
      viewBox="0 0 39 66"
      width="50px"
      height="84px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7728 28.8562V0.0521073L23.182 0.0521073L23.182 28.8562H15.7728ZM23.182 63.3093H15.7728V53.7463L0.178974 39.7032L5.29077 
33.7586L9.6559 37.7791C11.0535 39.081 12.2405 40.268 13.2169 41.3401C14.2125 42.4314 15.0644 43.5418 15.7728 44.6714V27.6991H23.182V44.6714C23.8904
 43.5418 24.7328 42.4314 25.7092 41.3401C26.7048 40.268 27.9014 39.081 29.299 37.7791L33.6641 33.7586L38.7759 39.7032L23.182 53.7463V63.3093ZM23.182
  90.9563H15.7728V62.1522H23.182L23.182 90.9563Z"
        fill="#E6FF00"
      />
    </svg>
  </div>
)
const WpAcfSubheaderModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfSubHeaderBlock
  const sectionAttributes = moduleData.attributes
  const defaultId = `subheader-${Math.floor(Math.random() * 100)}`
  const sectionId = sectionAttributes.anchor || defaultId
  const floatingSymbolTag = `<oder eintauchen>`

  const image = getImage(blockData.image.localFile)
  const secondImage = getImage(blockData.secondImage?.localFile)

  const { openContactModal } = useStateContext()
  const formClick = () => {
    openContactModal()
  }

  return (
    <Section id={sectionId}>
      <HeadlineWrap
        className={
          blockData.doubleImage && secondImage !== null
            ? "pt-80 md:pt-0"
            : "pt-64 md:pt-0"
        }
      >
        <h1 className="relative font-bold headline-subheader font-ubuntu text-54 md:text-52 lg:text-64 xl:text-80">
          <span
            className="relative text-right"
            data-start-symbol={blockData.startSymbol}
            data-end-symbol={blockData.endSymbol}
          >
            <span className="absolute text-5xl transform -translate-x-1/2 -translate-y-1/2 font-firacode text-blue md:top-8 lg:top-10 xl:top-12 -left-20">
              {parse(`;}=>`)}
            </span>
            {parse(blockData.headline)}
          </span>
        </h1>
      </HeadlineWrap>

      <DescriptionGrid>
        <motion.div>
          <GatsbyImage
            loading="eager"
            objectFit="contain"
            className="w-full mt-24 md:mt-0 !absolute top-0 right-0 md:!relative max-h-[250px] md:max-h-[450px]"
            image={image}
            alt={blockData.image?.altText}
          />
          {blockData.doubleImage && secondImage !== null && (
            <GatsbyImage
              loading="eager"
              objectFit="contain"
              className="w-full mt-24 md:mt-0 !absolute top-20 right-20 max-h-[250px] md:max-h-[450px]"
              image={secondImage}
              alt={blockData.secondImage?.altText}
            />
          )}
        </motion.div>
        <Description className="copy md:mt-8">
          <p>{blockData.copy !== null && parse(blockData.copy)}</p>
          {blockData.button !== null && (
            <Button
              onClick={
                blockData.button.url.includes("kontakt") ? formClick : undefined
              }
              buttonText={blockData.button.title}
              buttonHref={
                blockData.button.url.includes("kontakt")
                  ? "/"
                  : blockData.button.url
              }
              buttonClasses="bg-blue text-white mr-auto"
            />
          )}
          <div className="relative flex flex-col floating-symbol pt-c25">
            {blockData.button !== null && (
              <span className="font-normal font-firacode text-symbolSm md:text-symbolMd 2xl:text-symbolXl text-customGrey">
                {floatingSymbolTag}
              </span>
            )}
            <MovingElement
              speed={0.05}
              direction={{
                negative: "",
                axis: "y",
              }}
              content={false}
              className="hidden pt-4 pb-8 mx-auto transition-all duration-300 ease-linear transform md:block"
            >
              <ArrowIcon className="mx-auto duration-300 ease-linear transform mtransition-all scroll-element" />
            </MovingElement>
          </div>
        </Description>
      </DescriptionGrid>
      <Bracket>{`}`}</Bracket>
    </Section>
  )
}

WpAcfSubheaderModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfSubheaderModuleBlock
